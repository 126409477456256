.heroHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
align-items: center;
    background-color: #3b3b3b;
    height: 50px;
    top: 0;
    right: 0;
    left: 0;
    padding: 0 40px 0 40px;
  
}
         
.btn-grad {
    background-image: linear-gradient(to right, #232526 0%, #414345  51%, #232526  100%);
    margin: 10px;
    padding: 15px 45px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    display: block;
  }

  .btn-grad:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }
 
#sora {
    height: 25px;
}
#mail {
    text-decoration: none;
    color: rgb(228, 225, 225);
}
.heroHeader .mail{
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-evenly;
width: 35%;
border-radius: 20px;
padding: 5px 0 5px 0;
color: rgb(228, 225, 225);
font-weight: bold;

} 

.socialIcons{
display: flex;
flex-direction: row;

align-items: center;
background-color: #ffc513;
justify-content: space-evenly;
width: 15%;
padding: 0px 0 4px 0;
border-radius: 20px;
font-size: 26px;
margin-right: 7.5%;
-webkit-transition: background-color 2s ease-out;
-moz-transition: background-color 2s ease-out;
-o-transition: background-color 2s ease-out;
transition: background-color 2s ease-out;

}
.socialIcons:hover {
    background: rgb(255,255,255);
    background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,192,97,1) 100%);}
.dropdown {
    /* overflow: hidden; */
    float: left;
    /* position: relative; */
}
.liks{
    text-decoration: none;
    color:#000  !important;
font-size: 20px;
font-weight: bold;


}
.liks:hover{
    transition:all 0.3s ease-in-out;
   color: #ef7601 !important; 
}
.dropdown .dropbtn {
    font-size: 16px;    
    border: none;
    outline: none;
    color: rgb(0, 0, 0);
    background-color: inherit;
  background-color: #52423f;
  z-index: 0;
}
.dropbtnn{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 65px;
    background-color: transparent;

}
 .dropdown:hover .dropbtn {
    background-color: red;
}

.dropdown-content {
    margin-top: 20px !important;
    display: none;
    background-color: #e4d9d9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 100;
    width: 210px;
    top: 35px;
    border-radius: 10px;
margin-top: 5px;
position: absolute;
}

.dropdown-content a {
    transition: all .2s ease-in-out;
    float: none;
color: black;
   padding-top: 10px;
    text-decoration: none;
    display: block;
    text-align: left;
padding: 12px;
z-index: 10000000;
}

.dropdown-content a:hover {
    padding-right: 70px;
    background-color: rgb(255, 254, 254);
 
border-left:5px solid rgb(236, 10, 10) ;
border-top-left-radius: 11px;
border-bottom-left-radius: 11px;
/* top: -50%;
left: -50%;
transform: translate(-50%,-50%); */
}

.dropdown:hover .dropdown-content {
    display: block;
    border-radius: 10px;
    position: absolute;
   top: 30px;
   right: -65%;

}
body{
    position: relative;
}
.gpt3__navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    position: sticky;
    padding: 0 30px 0 30px;
z-index: 10;
}
.navShadow{
height: 80px;
    box-shadow: rgba(138, 132, 132, 0.089) 0px 15px 25px, rgba(0, 0, 0, 0) 0px 5px 10px;}
.gpt3__navbar-links{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.gpt3__navbar-links_logo{
    margin-right: 3rem;
}

.gpt3__navbar-links_logo img{
    width: 100px;
    height: 75px;
}

.gpt3__navbar-links_container{
    display: flex;
    flex-direction: row;
     display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 40px
}

.gpt3__navbar-sign{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.gpt3__navbar-links_container p,
.gpt3__navbar-sign p,
.gpt3__navbar-menu_container p{
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    margin: 0 1rem;
    cursor: pointer;
}

.gpt3__navbar-links_container h4,
.gpt3__navbar-sign h4,
.gpt3__navbar-menu_container h4{
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    margin: 0 1rem;
    cursor: pointer;
}

.gpt3__navbar-sign button,
.gpt3__navbar-menu_container button {
    padding: 0.5rem 1rem;
    background-color: #FF4820;
    border-radius: 0.5rem;
    font-size: 18px;
    font-family: var(--font-family);
    font-weight: 500;
    line-height: 25px;
    color: #fff;
    border: 0;
    outline: none;
}

.gpt3__navbar-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.gpt3__navbar-menu{
    margin-left: 1rem;
    display: none;
    position: relative;
}

.gpt3__navbar-menu svg{
    cursor: pointer;
}

.gpt3__navbar-menu_container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    text-align: center;
    background: rgba(25, 84, 161, 0.884);
    padding: 1rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1.5rem;
    min-width: 210px;
    border-radius: 20px;
    box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
}

.gpt3__navbar-menu_container p {
    margin: 1.5rem 0;   

}
/* .gpt3__navbar-menu_container-links{
    background-color: #FF4820;
    width: 100%;
} */
.gpt3__navbar-menu_container-links-sign {
    display: none;
}

@media screen and (max-width:1050px) {
    .gpt3__navbar-links_container{
        display: none;
    }
    .gpt3__navbar-menu{
        display: flex;
    }
}

@media screen and (max-width:700px) {
    .heroHeader{
        height: 100%;
        padding: 1rem 1rem;    
    }
    
        .heroHeader .mail{
            height: 80%;
          width: 70%;
          font-size: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
justify-content: flex-start;      
  }

  .socialIcons{
    width: 34%;
    flex-direction: row;
    font-size: 22px !important;
  }
   h4:nth-child(5){
    order: 1;
  }

 h4:nth-child(3) {
    order: 2;
  }
  h4:nth-child(4) {
    order: 3;
  }
  h4:nth-child(2) {
    order: 4;
  }

  h4:nth-child(1) {
    order: 5;
  }
    .dropbtnn{
      padding: 0;
      height: 15px;
    }
    .gpt3__navbar{
        padding: 2rem 1rem;
    }
    .gpt3__navbar-menu_container{
        top: 20px;
        width: 500px;
        height: 200px;
        height: fit-content;
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

@media screen and (max-width:550px) {
    .gpt3__navbar{
        padding: 2rem 1rem;
    }
    .gpt3__navbar-sign{
        display: none;
    }
    p:nth-child(5){
        order: 1;
      }
    
     p:nth-child(3) {
        order: 2;
      }
      p:nth-child(4) {
        order: 3;
      }
      p:nth-child(2) {
        order: 4;
      }
    
      p:nth-child(1) {
        order: 5;
      }
    .gpt3__navbar-menu_container{
        top: 20px;
        height: 200px;
        width: 250px;
        height: fit-content;
        padding-top: 20px;
        padding-bottom: 20px;

    }
    .gpt3__navbar-menu_container-links-sign{
        display: block;
    }
}