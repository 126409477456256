
.company-slide{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #ffffff;
    font-weight: bold;
    border-radius: 20px;
    width: 75%;
}
.company-slide img{
    width: 150px;
    height: 100px;
}
#companyContainer{
    margin-top: -20px;
z-index: -20; 

}
.suitableSection{
    display: flex;      
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    flex: 100%;
    height: 100vh;
    padding: 0 50px 0 50px;
  
}
.suitable-rightSide{
    display: flex;
    flex: 50%;
    flex-direction: column;
    /* margin-bottom: 20px; */
   
}
.suitable-rightSide >img{
    width: 100%;
    height: 100%;
}
.suitable-leftSide{
    display: flex;
    width: 50%;
text-align: left;
flex-direction: column;
padding: 20px;
position: relative;
}
.carSlide{
    z-index: -1;
}
#soraaa{
    width: 150px;
    height: 150px;
}
.suit-slide{
    display: flex;
    justify-content: center;
    align-items: center;
}
.suit-slide img{
    
    object-fit: contain;

    width: fit-content;
}
@media screen and (max-width:600px) {

    .suitableSection{
        flex-direction: column;
        height: 100%;
        padding: 30px 0 30px 0;
    }

    .suitable-rightSide{
        display: flex;
        flex: 100%;
       padding: 20px;
        width: 100%;

    }
    .suitable-rightSide >img{
        width: 100%;
        height: 100%;
    }
    .suitable-leftSide{
        display: flex;
        flex: 100%;
        width: 100%;
           justify-content: center;
   align-items: center;
   text-align: center;
    }
    #swip{
        width:300px;
        
        }
}