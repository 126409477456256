.gallery {
    height: 450px;
}
.gallery img{

    height:400px;
    margin: auto;
}
.gallery h1{
    text-align: center;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.gallery p{color: black;}

.carousel-indicators [data-bs-target]{
    display: none;
}