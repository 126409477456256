@import url(https://fonts.googleapis.com/earlyaccess/droidarabicnaskh.css);

.Productscontact{
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  flex-direction: column;
  background-image:  linear-gradient(100deg, #271b1be7 100%, rgba(48, 25, 25, 0.774) 100%),url(./back1.jpg);

         background-color: #3b3b3b ;
         background-size: cover;
         -webkit-background-size: cover;
         width: 100%;
         height: 400px;
         overflow: hidden;
         position: relative;
        
     
       
 }
 #contactimg{
  width: 700px;
  margin: auto;
 }
 .Productscontact::after {
     content: "";
     width: 200%;
     height: 0;
     padding-top: 100%;
     border-radius: 47%;
     background: #ffffff;
     position: absolute;
     top: 70%;
     left: 50%;
     transform: translateX(-50%);
   }
   .Productscontact p:first-child{
     font-size: 2.5rem;
     font-weight: bold;
   }
  
.mapcol iframe{
  width:600px; height:490px; border:0;

}
#ali{
  margin-left: 70px;

    width: 500px;
 }
#arabic-alert1{
  direction: rtl;
  margin-left: 90px;

}
#facearabic{
padding:10px ;
  direction: rtl;

font-size: 30px;}
#facearabic a{
  font-family: 'Droid Arabic Naskh', serif;
  text-decoration: none;

  color: black;
  font-family: 400;
  margin-right: 40px;
  font-size: 20px;
}

#facearabic:hover a{
  color: #ef7601;
  font-size: 22px;
  transition-duration: 3s;
    margin-right: 100px;
  text-decoration: none;
}
@media only screen and (max-width: 600px) {
  .mapcol iframe{
    width:370px; height:320px; border:0;
  
  }
  #ali{
    margin-left: 0px;
      width: 100%;
   }
  #arabic-alert1{
    margin-left: 0px;

    direction: rtl;
  }
  
 
  #facearabic{
    padding:10px ;
    font-size: 30px;}
    #facearabic a{
      font-family: 'Droid Arabic Naskh', serif;
      text-decoration: none;
      color: black;
      margin-right: 30px;
      font-size: 16px;
    }
    #facearabic:hover a{
      color: #ef7601;
      font-size: 20px;
      transition-duration: 3s;
        margin-right: 70px;
      text-decoration: none;
    }
}
#sales {
  width: 500px;
  float: right;
  direction: rtl;
}
#sales a{
 
  font-size: 20px;
  text-decoration: none;
  font-weight: 100px;
  color: #3b3b3b;
  margin-right: 60px;
 }
 #sales a:hover{
  text-decoration: none;
  color: #ef7601;
  font-size: 22px;
  transition-duration: 3s;
  margin-right: 100px;
 }