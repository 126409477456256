
.allwrps {
    display: flex;
    justify-content:  center;
    align-items: center;
    flex-wrap: wrap;
}
.singleCard{
  width: 200px;
  height: 200px;
}
.single {
    width: 400px;
    height: 400px;

    text-align: center;
    line-height: 200px;
   margin: 0 20px;
    margin-top: 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    transition: .5s ;  
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    border-radius: 20px;
    position: relative;
    margin-bottom: 20px;
        position: relative;
       
        background-color: rgb(184, 184, 184);
        color:#fff;
        overflow: hidden;
      
      
}



.newsCaption {
    position: absolute;
    top: auto;
    bottom: 0;
    opacity: .6;
    left: 0;
    width: 100%;
    height: 100%;
 
    background-color: #04003f;
    padding: 15px;
    -webkit-transform: translateY(80%);
            transform: translateY(80%);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transition: opacity 0.1s 0.3s, -webkit-transform 0.4s;
    transition: opacity 0.1s 0.3s, -webkit-transform 0.4s;
    transition: transform 0.4s, opacity 0.1s 0.3s;
    transition: transform 0.4s, opacity 0.1s 0.3s, -webkit-transform 0.4s;
  }
  
  .newsCaption-title {
    margin-top: 0px;
    font-size: 21px;
  }
  
  .newsCaption-content {
    margin: 0;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%)
  }
  

  
  .news-Slide-up:hover .newsCaption {
    opacity: .7;
    }
  
.single img{
  width: fit-content;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%,-30%);
  width: 150px;
  height: 200px;

}
.single:hover img{
  width: fit-content;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%,-30%);
  width: 250px;
  height: 300px;
  transition-duration: 2s;

}
link{
  text-decoration: none;
  color: #fff !important;
}

.btns {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 50px;

}

.btns button {
    width: 130px;
    height: 40px;
    border: none;
    outline: none;
    border-radius: 7px;
    margin: 0 10px;
    cursor: pointer;
    transition: .3s;
}

.btns button:hover,.btns button.active {
    background: rgb(43, 92, 226);
    color: #fff;
}
@media screen and (max-width:600px){
  .btns button{
    margin: 10px;
    width: 100px;
  }
 
}


@media screen and (max-width:1000px){
  .btns{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

  }
  .btns button{
    margin: 10px;
  }
}
