
.footer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 370px;
    width: 100%;
    flex-direction: column;
    background-image: linear-gradient(100deg, #000000e7 100%, rgba(0, 0, 0, 0.774) 100%),url(../../imgs//footerbackb.jpg);
   background-position: center center;
   background-repeat: no-repeat;
   background-size: cover;
   
    border-top-left-radius: 10%;
    border-top-right-radius: 10%;
    margin-top: 10px;
    color: #ffffff;
  position: relative;
 
}
.footerContent{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
#logoz{
    width: 150px;
    height: 100px;
}
.footer img{
    width: 200px;
    height: 130px;
    margin-bottom: 20px;
}
.contactIcons-footer{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
 margin-bottom: 40px;
}
.contactIcons-footer span{
    font-weight: bold;
color: #fff;
    margin-right: 10px;
    
}
.footerIcon{
    color: #ef7601 !important;
}
.copyRight{
    position: absolute;
    bottom: 0;
    color: #fff;
    font-weight: bold;
    background-color: #ef7601;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
   
   
}
.copyRight p{
    display: flex;
    align-items: center;
    justify-content: center;
margin: auto;
}

@media screen and (max-width:600px){

    .footer{
        height: 475px;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        flex-direction: column;
       padding: 20px;
        }
    .footer img{
        width: 150px;
        height: 100px;
    }
    .contactIcons-footer{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: fit-content;
        justify-content:center;
     
    }
    .contactIcons-footer span{
        margin-top: 10px;
        font-size: 12px;
    }
    .copyRight{
        background-color: tomato;
   font-size: 10px;
    }
}