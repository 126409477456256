.aboutSection{
display: flex;
flex-direction: row;
justify-content: space-evenly;
flex-wrap: wrap;
margin-bottom: 50px;
}

.aboutCard1,.aboutCard2,.aboutCard3{
width: 300px;
height: 500px;
background-color: aliceblue;
border-radius: 20px;
box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
transition: transform 0.2s ease-in-out;
position: relative;

}
.aboutCard1:hover {
    transform: scale(1.05);
  }
  

.aboutCard1>img{
    width: 100%;
    height: 250px;
    border-radius: 20px;

}
.aboutCard2>img{
    width: 100%;
    border-radius: 20px;
    height: 250px;

}
.aboutCard3>img{
    width: 100%;
    border-radius: 20px;
    height: 250px;


}
.aboutCard-content{
    display: flex;
    text-align: left;
    flex-direction: column;
    padding: 18px;
justify-content: center;
margin-top: 20px;
margin-bottom: 20px;
}
.readmoreBtn{
    position: absolute;
    bottom: 15px;
top: 93%;
left: 50%;
transform: translate(-50%,-50%);
text-decoration: none;
width: 130px;
height: 40px;
background-color: #ef7601;
color: #000;
text-align: center;
display: flex;
align-items: center;
justify-content: center;
border-radius: 20px;
transition: all 0.4s ease-in-out;
font-weight: bold;
}
.readmoreBtn:hover{
    transition: all 0.4s ease-in-out;
    color:#fff;
    background-color: #3b3b3b;
}

@media screen and (max-width:600px) {

    .aboutSection{
        width: 100%;
        padding-top: 30px;
        padding-bottom: 30px;
        height: 100%;
    }
    .aboutCard1,.aboutCard2,.aboutCard3{
        margin: 15px;
        height: fit-content;
        width:100%;
    }
    .aboutCard-content{
        margin-bottom: 50px;
    }
}