#app {
    height: 100%;
  }
  html,
  body {
    height: 100%;
  }
  #tit{
    padding-top: 20px;
    text-align: center;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  }

  body {
    background: #eee;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
    position: relative;
  }
  
  .swiper {
    width: 100%;
    height: 100vh;

  }
  
  /* .swiper-slide {
    font-size: 18px;
    background: #fff;
 

  } */
  


  .slideContainer{
    height: 100%;
    width: 100%;
    display: flex;
    flex: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
  .rightSide{
    display: flex;
    flex-direction: column;
    flex: 50%;
justify-content: center;
align-items: center;
padding-top: 100px;


  }
  #slide1{
    background-image: linear-gradient(60deg, #2c0a0ab6 50%, #00000081 100%),url(../../imgs/slide1.jpg);
    background-position: bottom bottom;
    background-size: cover;
 
  }

  #slide2{
    background-image:  linear-gradient(60deg, #2c0a0ab6 50%, #00000081 100%),url(../../imgs/slide2.jpg);
    background-position: bottom bottom;
    background-size: cover;
 
  }
  #slide3{
    background-image: linear-gradient(60deg, #2c0a0ab6 50%, #00000081 100%),url(../../imgs/slide3.jpg);
    background-position: bottom bottom;
    background-size: cover;
 
  }
  #slide4{
    background-image: linear-gradient(60deg, #2c0a0ab6 50%, #00000081 100%),url(../../imgs/slide4.jpg);
    background-position: bottom bottom;
    background-size: cover;
 
  }
  #logobr{
    width: 180px;
    height: 120px;
    margin-right: 130px;
  }
  #logobr2{
    width: 180px;
    height: 120px;
  }
  .img1,.img2,.img3,.img4{
    width: 350px;
    height: 500px;
    margin-bottom: 30px;
  }
  .leftSide_content {
    line-height: 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    width: 100%;

  }

  .leftSide{
    display: flex;
    flex-direction: column;
    flex: 50%;
    justify-content: center;
    align-items: center;
  margin-top: 120px;

  }
  .leftSide p{
    font-size: 50px;
    color: #ffffff;
    font-weight: 900;
    text-transform: uppercase;
  }
  .lionContent{
    display: flex;
    flex-direction: column;
justify-content: center;
    align-items: flex-start;
    color: #fff;
    font-size: 50px;
    font-weight: 900;
    text-transform: uppercase;
    line-height: 1.2;
  }
.icon1{
background-color:transparent;
}
.icon2{
    background-color:transparent;
    
    }

/* search */

  
  .constrain{
   display: flex;
flex-direction: row;
width: 100%;
margin-top: 30px;
align-items: center;
justify-content: center;
margin-bottom: 50px;
}
  

  
  .input-transparent{
  font-size: 16px;
  line-height: 1.5;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  border: none;
  background: rgba(22, 13, 13, 0.712);
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'><path fill='%23FFFFFF' d='M13.22 14.63a8 8 0 1 1 1.41-1.41l4.29 4.29a1 1 0 1 1-1.41 1.41l-4.29-4.29zm-.66-2.07a6 6 0 1 0-8.49-8.49 6 6 0 0 0 8.49 8.49z'></path></svg>");
  background-repeat: no-repeat;
  background-position: 10px 10px;
  background-size: 20px 20px;
  border-radius: 10px;
  width: 85%;
  padding: .5em 1em .5em 2.5em;
  margin-bottom: 0px;

  } 
  
  .input-transparent::placeholder{
    color: rgb(253, 228, 228);
    font-weight: bold;
  }
  
  .input-transparent:focus {
    outline: none;
    background: #5A9FC7;
    text-shadow: none;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'><path fill='%238C92A0' d='M13.22 14.63a8 8 0 1 1 1.41-1.41l4.29 4.29a1 1 0 1 1-1.41 1.41l-4.29-4.29zm-.66-2.07a6 6 0 1 0-8.49-8.49 6 6 0 0 0 8.49 8.49z'></path></svg>");
    background-repeat: no-repeat;
  background-position: 10px 10px;
  background-size: 20px 20px;
  }
  
  .bg-gradient{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    border-radius: 10px;
    margin-left: -40px;
    margin-right: -40px;
    width: 90%;
    background-image: linear-gradient(-135deg, #5A9FC7 0%, #6DC2D7 100%);
  }
  @media screen and (max-width:600px) {
    .slideContainer{
    
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100vh;
    }
    .rightSide{
      display: flex;
align-items: center;
justify-content: center;
 
  flex: 100%;
  
    }
    .img1,.img2,.img4{
      width: 210px;
      height: 260px;
padding-bottom: 20px;
    }
    .img3{
      width: 210px; 
      height: 260px;
    }
    .leftSide_content {
     display: flex;
     align-items: center;
     justify-content: center;
     
     font-size: 20px;
  
    }
  .rightSide{
margin-bottom: 300px;
  }
    .leftSide{
      display: flex;
      flex-direction: column;
      flex: 100%;
     text-align: center;
      margin-top: 130px;
padding: 20px;
padding-top: 300px;
    }
    .leftSide p{
      font-size: 25px;
      
    }
    .lionContent{
      display: flex;
   font-size: 30px;
   font-weight: bold;
   align-items: center;
   justify-content: center;
   line-height: 1.5;
   padding: 0;
   margin-top: 150px;
    }
    #slide1,#slide2,#slide3{
      background-position: center center;
      background-size: 100vw 100vh;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
