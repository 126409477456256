body{
    background-color: #ffffff;
    position: relative;
}
.Products{
 display: flex;
 justify-content: center;
 align-items: center;
 color: #ffffff;
 flex-direction: column;
      background-image:  linear-gradient(100deg, #271b1be7 100%, rgba(48, 25, 25, 0.774) 100%),url(./productImg.jpg);
      background-position: center center;
      background-size: cover;
        background-size: cover;
        -webkit-background-size: cover;
        width: 100%;
        height: 400px;
        overflow: hidden;
        position: relative;
       
        color: #ef7601 !important;
      
}
.Products::after {
    content: "";
    width: 200%;
    height: 0;
    padding-top: 100%;
    border-radius: 47%;
    background: #ffffff;
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translateX(-50%);
  }
  .Products p:first-child{
    font-size: 2.5rem;
    font-weight: bold;
   color: #ef7601;
  }
  .productCards{
   
    display: flex;
flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: -50px;
  }

  .productCards .cards-of-products{
    width: 350px;
    margin: 20px;
    height: 400px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 22px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
   
    transition: transform .7s;
}

.cards-of-products:hover{
    transform: scale(1.1);
  }
  .cards-of-products img{
    width: 150px;
  }
  .productName{
    font-size: 22px;
    text-transform: capitalize;
    font-weight: 900;
  }
  .read-more-product{
    text-decoration: none;
gap: 100px;
color: #2b49f5;
transition: all .2s ease-in-out;
  }
   .read-more-product:hover { transform: scale(1.1); }
   @media screen and (max-width:600px) {
    .Products{
        display: flex;
        flex-direction: column;
        text-align: center;
    }
    .Products p:first-child{
        font-size: 2rem;
    }
   }